import React from "react";
import styled from "styled-components";

import { device } from "../constants/variables";
import image from "../images/cover.jpg";

const Cover = () => <BgImage src={image}></BgImage>;

const BgImage = styled.img`
  height: 70vh;
  width: 100%;
  @media ${device.laptop} {
    min-height: 45vh;
  }
`;

export default Cover;
