import React from "react";
import styled from "styled-components";

import {
  device,
  contentWidth,
  paddingLarge,
  xMarginMobile,
  colors,
} from "../constants/variables";

import Title from "./shared/Title";

const Contact = () => (
  <section id="cenik">
    <Wrapper>
      <Container>
        <Left>
          <Content>
            <Title>Cenik</Title>
            <Item>
              <Name>Uvodni razgovor</Name>
              <Value>60 EUR</Value>
            </Item>
            <Line />
            <Item>
              <Name>Ura individualne psihoterapije</Name>
              <Value>60 EUR*</Value>
            </Item>
            <Text>Psihoterapevtsko srečanje traja 45 minut.</Text>
            <Text>*Ceno je mogoče po dogovoru prilagoditi.</Text>
          </Content>
        </Left>
        <Right>
          <Title>Kontakt</Title>

          <Text>
            <Link href="s.skerlep@gmail.com" target="_top">
              s.skerlep@gmail.com
            </Link>
            <Number>031 833 621</Number>
            <div>
              Lokacija psihoterapevtskih srečanj:
              <br />
              Prostori Centra Devet
              <br /> Cankarjeva 9 <br /> 1000 Ljubljana <br />
            </div>
          </Text>

          {/* <Text>
            <div>
              Psihoterapija, Saga Lucija Škerlep s.p.
              <br />
              Černetova ulica 6<br />
              1000 Ljubljana <br />
              TRR: SI56 0201 1026 3905 586 <br />
              Nova Ljubljanska banka d.d., Ljubljana
            </div>
          </Text> */}
        </Right>
      </Container>
    </Wrapper>
  </section>
);

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingLarge} 0;
`;

const Container = styled.div`
  max-width: ${contentWidth};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
  }
  @media ${device.mobile} {
    flex-direction: column;
  }
  display: flex;
`;

const Content = styled.div``;

const Left = styled.div`
  width: 65%;
  padding-right: 80px;

  @media ${device.mobile} {
    width: 100%;
    padding-right: 0;
  }
`;

const Link = styled.a`
  color: ${colors.primary};

  &:hover {
    color: ${colors.secondary};
  }
`;

const Line = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${colors.primary};
  opacity: 0.3;
  margin: 8px 0;
`;

const Right = styled.div`
  width: 35%;

  @media ${device.mobile} {
    width: 100%;
    padding-right: 0;
    margin-top: 80px;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Name = styled.div``;

const Value = styled.div``;

const Text = styled.div`
  margin-top: 24px;
  text-align: left;
`;

const Number = styled.div`
  margin-bottom: 24px;
`;

export default Contact;
