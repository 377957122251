import React from "react";
import styled from "styled-components";

import Wrapper from "./shared/Wrapper";
import Container from "./shared/Container";
import { colors } from "../constants/variables";

import image from "../images/cover-small.png";

const Footer = () => (
  <StyledWrapper>
    <Image src={image} />
    <StyledContainer>© Saga Lucija Škerlep in Hana Tavčar</StyledContainer>
  </StyledWrapper>
);

const StyledWrapper = styled(Wrapper)`
  padding-top: 0 !important;
  position: relative;
  background-color: ${colors.white};
  z-index: 3;
`;

const StyledContainer = styled(Container)`
  text-align: center;
  padding: 40px 0 40px 0;
  color: #888a87;
`;

const Image = styled.img`
  margin-top: -10px;
  height: 15px;
  width: 100%;
  object-fit: cover;
`;

export default Footer;
