import styled from "styled-components";

import { paddingSmall } from "../../constants/variables";

const Wrapper = styled.div`
  width: 100%;
  padding: ${paddingSmall} 0;
`;

export default Wrapper;
