import React from "react";
import styled from "styled-components";

import { device, colors } from "../constants/variables";
import image from "../images/lucija2.png";

import Wrapper from "./shared/Wrapper";
import Container from "./shared/Container";

const About = () => (
  <Wrapper>
    <Container>
      <Content>
        <Text>
          <Paragraph>
            Psihoterapija je proces, tekom katerega prepoznavamo, raziskujemo in
            razrešujemo <b>osebne</b> in <b>medosebne stiske</b>. Ko občutka
            stiske ni, je psihoterapija lahko tudi proces znotraj katerega{" "}
            <b>
              odkrivamo globine našega čustvovanja, doživljanja in razumevanja
            </b>{" "}
            sebe ter sveta okoli nas. Proces temelji na konstantnem in zaupnem{" "}
            <b>odnosu med terapevtom in klientom</b>. Eden izmed bistvenih
            ciljev, h kateremu stremim, je spremljanje pacienta na poti k{" "}
            <b>odkrivanju sebe</b>. Psihoanalitična psihoterapija je dolgotrajen
            proces. Poteka vsaj <b>enkrat tedensko</b>. Razbremenitev je lahko
            prisotna že ob odločitvi za delo s seboj, za dolgoročne simptomatske
            in osebnostne spremembe pa je <b>potreben čas</b>.
          </Paragraph>
          <Paragraph>
            Med področji, s katerimi se ukvarjam, so <b>tesnobna</b> stanja ali
            motnje, <b>psihosomatska obolenja</b>, iskanje <b>smisla</b>,
            iskanje <b>spolne identitete</b>, <b>strah pred smrtjo</b>, občutek{" "}
            <b>praznine</b>, <b>depresivnost</b>, <b>žalovanje</b>,{" "}
            <b>zmedenost</b>, <b>nizka samozavest</b>, močni občutki{" "}
            <b>sramu</b>, <b>krivde</b> ali <b>strahu</b>, težave v{" "}
            <b>partnerstvu</b> in <b>starševstvu</b>, <b>samomorilnost</b>,
            težave v <b>spolnosti</b>, <b>kronična utrujenost</b>, nerazumljiva{" "}
            <b>doživetja</b> ali <b>videnja</b> in raziskovanje <b>sanj</b>.
          </Paragraph>
          <Paragraph>
            Ime mi je Saga Lucija Škerlep. Sem specializantka{" "}
            <b>psihoanalitične psihoterapije</b> pod supervizijo. Prvotno
            izobrazbo sem pridobila na Biotehniški fakulteti v Ljubljani, kjer
            sem leta 2017 diplomirala iz agronomije. Leta 2016 sem se vpisala na
            študij psihoterapevtske znanosti na{" "}
            <b>Univerzi Sigmunda Freuda v Ljubljani</b>, kjer sem diplomirala
            leta 2019 z naslovom Primerjava šamanizma in psihodinamskih
            psihoterapij. Na isti univerzi sem zaključila tudi štiriletni
            specialistični študij iz psihoanalitične psihoterapije.
          </Paragraph>
          <Paragraph>
            Osebno psihoanalitično izkušnjo sem v obsegu 420 ur opravljala pri
            mag. Mihu Štruklju, skupinsko analizo pa pri prof. dr. Emilu
            Benediku v obsegu 80 ur. Za moj učni proces sta bili pomembni tudi
            udeležba na delavnici metode "Compassionate Inquiry", ki jo je vodil
            dr. Gabor Maté leta 2019 v Winnipegu, in psihiatrična praksa pri
            prof. dr. Mojci Zvezdani Dernovšek leta 2021. Po končanem diplomskem
            študiju nadaljujem z magistrskim programom ter se redno izobražujem
            na strokovnih seminarjih, delavnicah in konferencah. Raziskovalno se
            trenutno posvečam skupinski dinamiki in stiskam žensk v poporodnem
            obdobju, pod mentorstvom dr. Andreje Poljanec. V okviru tega vodim{" "}
            <b>Pogovorne skupine za ženske v poporodnem obdobju</b>.
          </Paragraph>
          <Paragraph>
            Zavezana sem k <b>popolni zaupnosti</b> in delujem v skladu z{" "}
            <Link
              href="https://ezpps.org/wp-content/uploads/2021/02/Eticni-kodeks-EZPPS.pdf"
              target="blank"
            >
              etičnim kodeksom
            </Link>{" "}
            <Link href="https://ezpps.org/" target="blank">
              Evropskega združenja psihoanalitičnih pristopov Slovenija (EZPPS)
            </Link>
            V EZPPS aktivno delujem v strokovnem svetu združenja, pasivno pa sem
            udeležena tudi v Slovenski krovni zvezi za psihoterapijo (SKZP).
            Psihoterapijo izvajam v slovenskem in angleškem jeziku. Pri delu
            sodelujem s supervizorkama Marto Jenko in Ingrid Plankar. Poleg
            samostojnega psihototerapevtskega dela sem udeležena v projekt{" "}
            <Link href="https://www.tosemjaz.net/" target="blank">
              NIJZ #tosemjaz
            </Link>
            .
          </Paragraph>
        </Text>
        <ImageWrapper>
          <Image src={image}></Image>
        </ImageWrapper>
      </Content>
    </Container>
  </Wrapper>
);

const Content = styled.div`
  display: flex;
  padding-top: 40px;

  @media ${device.mobile} {
    flex-direction: column;
  }
`;

const Text = styled.div`
  width: 65%;
  padding-right: 80px;

  @media ${device.mobile} {
    width: 100%;
    padding-right: 0;
  }
`;

const Paragraph = styled.div`
  margin-bottom: 24px;
`;

const Link = styled.a`
  color: ${colors.primary};

  &:hover {
    color: ${colors.secondary};
  }
`;

const ImageWrapper = styled.div`
  width: 35%;

  @media ${device.mobile} {
    width: 100%;
    margin-top: 28px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 400px;
  margin-top: 256px;
  object-fit: cover;

  @media ${device.mobile} {
    flex-direction: column;
    width: 100%;
    height: 400px;
    margin-left: 0;
    margin-top: 0;
  }
`;

export default About;
