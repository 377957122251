import styled from "styled-components";

import { colors, device } from "../../constants/variables";

export const Title = styled.div`
  margin-bottom: 40px;
  font-weight: 600;
  color: ${colors.black};
  font-size: 32px;

  @media ${device.mobile} {
    font-size: 24px;
  }
`;

export default Title;
