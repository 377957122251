import React from "react";
import "./App.css";
import styled from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";

import { colors } from "./constants/variables";

import About from "./components/About";
import Cover from "./components/Cover";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Navigation from "./components/Navigation";

function App() {
  return (
    <Wrapper>
      <Router>
        <Navigation></Navigation>
        <Cover></Cover>
        <About></About>
        <Contact></Contact>
        <Footer></Footer>
      </Router>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100vh;
  color: ${colors.text};
`;

export default App;
