import React from "react";
import styled from "styled-components";
import { HashLink } from "react-router-hash-link";

import {
  device,
  contentWidth,
  paddingSmall,
  xMarginMobile,
  colors,
} from "../constants/variables";

const Navigation = () => (
  <Wrapper>
    <Content>
      <Name>Psihoterapija Škerlep</Name>
      <div></div>
      <Links>
        <StyledLink smooth to="/#cenik">
          Cenik in kontakt
        </StyledLink>
      </Links>
      <StyledLinkMobile smooth to="/#cenik">
        <MobileButton>Cenik in kontakt</MobileButton>
      </StyledLinkMobile>
    </Content>
  </Wrapper>
);

const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  background-color: ${colors.white};
  width: 100%;
  padding: ${xMarginMobile} 0;
  @media ${device.laptop} {
    padding: ${paddingSmall} 0;
  }
`;

const Content = styled.div`
  max-width: ${contentWidth};
  margin: 0 ${xMarginMobile};
  @media ${device.laptop} {
    margin: auto;
    margin-top: 4px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  @media ${device.mobile} {
    align-items: flex-start;
  }
`;

const MobileButton = styled.div`
  display: none;
  background-color: ${colors.primary};
  padding: 8px 16px;
  color: ${colors.white};
  @media ${device.mobile} {
    display: block;
    position: fixed;
    bottom: 30px;
    right: 30px;
  }
`;

const Name = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4em;
  font-family: "Bodoni Moda", serif;
  color: ${colors.black};

  @media ${device.mobile} {
    padding-top: 3px;
    font-size: 24px;
  }
`;

const Links = styled.div`
  display: flex;

  @media ${device.mobile} {
    display: none;
  }
`;

const StyledLink = styled(HashLink)`
  cursor: pointer;
  color: ${colors.text};
  font-weight: 500;
  &:hover {
    color: ${colors.primary};
  }
  &:not(:first-child) {
    margin-left: 16px;
  }
`;

const StyledLinkMobile = styled(StyledLink)`
  display: none;
  @media ${device.mobile} {
    display: block;
    position: fixed;
    bottom: 30px;
    right: 30px;
  }
`;

export default Navigation;
