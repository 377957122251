import styled from "styled-components";

import { device, contentWidth, xMarginMobile } from "../../constants/variables";

const Container = styled.div`
  margin: auto;
  max-width: ${contentWidth};
  @media ${device.mobile} {
    margin: 0 ${xMarginMobile};
  }
`;

export default Container;
